import { Tooltip as TooltipLib } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { TooltipProps } from './Tooltip.types';
import { Container } from './Tooltip.styles';
import styled from 'styled-components';
import { useId } from 'react';
import { Colors } from '../../../styles/colors';

export const Tooltip: React.FC<any> = ({
  children,
  title,
  hasArrow = true,
  onActionClick,
}: React.PropsWithChildren<TooltipProps>) => {
  const id = useId();

  return (
    <>
      <a data-tooltip-id={id} data-tooltip-delay-hide={300}>
        {title}
      </a>
      <TooltipLib
        id={id}
        render={() => <span>{children}</span>}
        style={{ backgroundColor: Colors.Primary, color: Colors.Black, maxWidth: '300px' }}
      />
    </>
  );
};
